import React from "react";
import "../css/App.css";
import Menu from "./Menu";

const MenuHeadingsAndRoutes = [
  "MUNEEB.DEV",
  ["My Projects", "/"],
  ["About Me", "/"],
  ["Contacts", "/"],
  ["Resume", "/"],
];

class App extends React.Component {
  render() {
    return (
      <div className="ui container">
        <Menu values={MenuHeadingsAndRoutes} />
      </div>
    );
  }
}

export default App;
