import React from "react";
import "../css/Menu.css";

const Menu = ({ values }) => {
  const menuHeadings = values.map((value, index) => {
    if (index === 0) {
      return (
        <div className="header item" key={index} href="/">
          {value}
        </div>
      );
    } else {
      return (
        <a className="item" key={index} href={`${value[1]}`}>
          {value[0]}
        </a>
      );
    }
  });

  return <div className="ui menu">{menuHeadings}</div>;
};

export default Menu;
